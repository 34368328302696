body {
	height: 90vh;
	/* overflow: hidden; */
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Verdana', 'Tahoma',
		'Helvetica', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.bg-light-custom {
	background-color: #f4f3f3;
}

::-webkit-scrollbar {
	width: 6px;
}

/* TO STYLE THE SCROLLBAR TRACK */
::-webkit-scrollbar-track {
	background-color: none;
}

/* TO STYLE THE SCROLLBAR THUMB */
::-webkit-scrollbar-thumb {
	background-color: lightgray;
	border-radius: 35px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.bg-x-dark {
	background-color: #b6ae99;
}

.bg-x-black {
	background-color: #262626;
}

.full-screen {
	max-height: 300px;
}

.p-left {
	padding-left: 15% !important;
}

.p-right-social {
	padding-right: 4% !important;
}

.p-left-sm {
	padding-left: 10% !important;
}

.p-left-links {
	padding-left: 5% !important;
}

.p-row {
	padding: 1% 4%;
}

.small-label {
	position: relative;
	top: 2vh;
	left: 2%;
	background-color: white;
	padding: 0 0.3vh 0.3vh 0.3vh;
	font-size: 1.2vh;
	color: #b6ae99;
}

.custom-txt {
	color: #b6ae99;
	font-weight: bold;
	text-align: center;
}

.custom-links-txt {
	color: white;
	text-align: center;
	font-size: 1.3vh;
	margin-left: 2.2vh;
	margin-right: 2.2vh;
}

.form-select,
.form-control {
	font-size: 1.4vh !important;
}

.fs-4c {
	font-size: 2.5vh;
}

.fs-6c {
	font-size: 1.6vh !important;
}

.fs-7 {
	font-size: 1.4vh;
}

.custom-btn,
.custom-btn-green,
.custom-btn-red {
	font-size: 1.3vh;
	border-radius: 3vh;
	padding: 0.5vh 2.8vh 0.5vh 2.8vh;
	text-transform: uppercase;
	text-align: center;
	border: solid 0.2vh;
	/* border-color: #64ba8b; */
}

#googleBtn {
	display: inline-block;
	background: white;
	color: #444;
	width: 190px;
	height: 42px;
	border-radius: 5px;
	border: thin solid #888;
	box-shadow: 1px 1px 1px grey;
	white-space: nowrap;
}
#googleBtn > span.icon {
	background: url('images/g-normal.png') transparent 5px 50% no-repeat;
	display: inline-block;
	vertical-align: middle;
	width: 42px;
	height: 42px;
	max-height: 42px;
}
#googleBtn > span.buttonText {
	display: inline-block;
	vertical-align: middle;
	font-size: 14px;
	font-weight: bold;
	/* Use the Roboto font that is loaded in the <head> */
	font-family: 'Roboto', sans-serif;
}

.custom-border-dark {
	border-color: #262626;
}

.custom-btn {
	background-color: white;
	color: #64ba8b;
}

.custom-btn:hover {
	background-color: #64ba8b;
	color: white;
}

.custom-btn-green {
	color: white;
	background-color: #64ba8b;
}

.custom-btn-green:hover {
	color: #64ba8b;
	background-color: white;
}

.custom-btn-red {
	color: white;
	background-color: #e8463d;
}

.custom-btn-red:hover {
	color: #e8463d;
	background-color: white;
}

.circle-btn {
	padding: 0.2vh 0.6vh 0.3vh 0.6vh;
	margin: 3vh 0 0 0;
}

.hover-x:hover {
	background-color: white;
	color: #b6ae99;
}

.hover-x {
	background-color: #b6ae99;
	color: white;
}

.header-all {
	padding: 1.5vh;
	/* height: 23vh; */
}
.header-left {
	border-top-left-radius: 3vh;
	border-top-right-radius: 3vh;
}

.appointments {
	/* margin: 0 1% 5% 5%; */
	/* padding: 5px 2rem 2rem 2rem; */
	border-bottom-left-radius: 3vh;
	border-bottom-right-radius: 3vh;
	max-height: 87vh;
}

.appointments > div.highlighted {
	background-color: #e8463d;
}

div.highlighted .card-client-category:nth-last-child(2) {
	color: white !important;
}

.negative-margin {
	margin-top: -4vh;
	margin-bottom: 4vh;
	min-height: 48vh !important;
}

.col3-5 {
	max-width: 29.17% !important;
}
.col8-5 {
	width: 70.83% !important;
}

Spinner {
	background-color: red;
}

.card-client-category {
	font-weight: light;
	font-size: 1.2vh;
}

.card-custom {
	/* color: #64ba8b; */
	/* border-style: dashed; */
	border-bottom: 1px solid #bebebe;
	/* border-color: red; */
	/* border-radius: 30px; */
}

.color-x {
	color: #b6ae99 !important;
}

.assign-form-label {
	margin: 0 0 -1.5vh 0;
}

.date-time {
	color: #b6ae99;
	border-radius: 1.5vh;
	border: solid 0.3vh #b6ae99;
	width: 45%;
}

::-webkit-calendar-picker-indicator {
	background-image: heart;
}

.modal {
	border-radius: 100px;
}

.calendar-view {
	background-color: white;
	/* border-radius: 2rem; */
	padding: 1%;
}

.rbc-calendar,
.rbc-toolbar,
.rbc-button-link {
	font-size: 1.5vh !important;
	/* margin: 0 !important; */
	/* padding: 0 !important; */
}

/* .rbc-calendar {
}
.rbc-button-link {

}

.rbc-toolbar {
} */

.img-style {
	max-height: 6vh;
}

.rbc-btn-group > button {
	padding: 0.3vh !important;
}

.info-window {

	/* height: 100px; */
	/* min-width: 160px; */
	background-color: #b6ae99;
	color: white;
	/* border-color: #b6ae99; */
	padding: 10px;
	border: 0 !important;
}

.gm-style .gm-style-iw-c,
.gm-style-iw-tc::after {
	background-color: #b6ae99 !important;
	padding: 0;
	border: 0 !important;
}

/* .gm-ui-hover-effect { */
/* border: none; */
/* display: none !important; */
/* } */

.visible {
	visibility: visible;
}
.invisible {
	visibility: hidden;
}

.full-date {
	width: 100%;
	border-radius: 0.5vh;
}

.icon {
	max-height: 2.5vh;
	color: #64ba8b;
	background-color: red;
	fill: blue;
	stroke: green;
}

.float-right {
	float: right;
}

.collapsed-to-top {
	overflow: hidden;
	height: 0;
}

@media (min-width: 1441px) {
	.appointment-modal > .modal-dialog {
		width: 650px;
		max-width: 650px;
	}
}

.gm-style .gm-style-iw-d {
	overflow: auto !important;
}

.gm-style-iw.gm-style-iw-c button {
	display: none !important;
}

.col > a {
	color: white;
	text-decoration: none;
}

.col > a:hover {
	color: lightgray;
}

.calendar-view.day .rbc-allday-cell .rbc-row-segment .rbc-event-content {
	white-space: normal;
	max-height: 2vh;
	overflow-wrap: anywhere;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td {
    overflow-wrap: anywhere;
}
